import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./assets/scss/main.scss";
import "primevue/resources/themes/saga-green/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import Fragment from "vue-fragment";
import $ from 'jquery';
import VueToast from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-sugar.css';
import Loading from "./components/Loading.vue";
import VueTheMask from "vue-the-mask";
import { Steps, Step } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import draggable from 'vuedraggable';

Vue.component('draggable', draggable);
Vue.use(VueTheMask);
Vue.use(VueToast, { duration: 2600, dismissible: true, position: "top-right" });
Vue.use(Fragment.Plugin);
Vue.use(Steps);
Vue.use(Step);

Vue.config.productionTip = false;
Vue.component('loading', Loading);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.directive('tooltip', function(el, binding){
  $(el).tooltip({
           title: binding.value,
           placement: binding.arg,
           trigger: 'hover'             
       })
})
Vue.directive('popover', function(el, binding){
  $(el).popover({
           content: binding.value,
           placement: binding.arg,
           trigger: 'click',
           html: true             
       })
})
