import { render, staticRenderFns } from "./ProdutosBuscar.vue?vue&type=template&id=75840c16"
import script from "./ProdutosBuscar.vue?vue&type=script&lang=js"
export * from "./ProdutosBuscar.vue?vue&type=script&lang=js"
import style0 from "./ProdutosBuscar.vue?vue&type=style&index=0&id=75840c16&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.92.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports