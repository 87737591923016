<template>
  <section class="header">

    <Dialog header="NOTIFICAÇÃO" :visible.sync="Popup" :modal="true"
            :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '80vw' }">
            <div class="row">
              <div class="col-8 m-auto">
                <div class="card">
                  <div class="modal-header">
                    <h5 class="card-title m-auto">{{ notificacao.title }}</h5>
                  </div>
                  <div class="card-body">
                    <p v-html="notificacao.text"></p>
                    <p class="hs">{{ notificacao._created | formatDate }}</p>
                  </div>
                </div>
              </div>


            </div>
          </Dialog>


    <div class="container">
      <div class="row d-flex justify-content-center align-items-center pt-2 pb-1">
        <div class="col-md-2 col-6">
          <router-link to="/">
            <img src="@/assets/logo.png" class="img-fluid" width="80" alt="logo" />
          </router-link>
        </div>

        <ProdutosBuscar  />
        <div class="col-6 d-md-flex d-flex col-md-6 justify-content-end">
          <!--
          <div
          v-if="$store.state.user.group == 'gestor'"
            class="mi-notifications d-flex align-items-center justify-content-center pr-3 position-relative pointer-event"
           
          >
            <span class="as-notifications" v-if="$store.state.cart.length">{{$store.state.cart.length}}</span>
            <span class="material-icons black cursor-pointer header-icon  not"   @click="openModal()" v-if="$store.state.user.group == 'gestor'">shopping_cart</span>
          </div>
          -->
          <div>
            <span class="material-icons not" @click="OpeNot"> notifications </span>
            <span class="numb" v-if="count > 0">{{ count }}</span>
          </div>
          <ul v-if="notlist && notifications.length > 0" class="ul-not">
            <li v-for="(not, t) in notifications" @click="PopupNot(not._id)" class="cursor-pointer" v-bind:key="t">
              <div class="p-2">
                <div class="card li-not">
                  <div class="card-body">
                    <h5 class="card-title">{{ not.title }}</h5>
                    <p class="card-text text-not">{{ not.text }}</p>
                    <span class="hs">{{ not._created | formatDate }}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul class="ul-not" v-if="notlist && notifications.length == 0">
            <li class="cursor-pointer">
              <div class="p-2">
                <div class="card li-not">
                  <div class="card-body">
                    <p class="card-text text-not">Sem notificação!</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div
            class="dropdown d-md-flex justify-content-center align-items-center white pl-3"
            id="user-menu"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              :src="'https://ui-avatars.com/api/?background=009de0&color=fff&name=' + $store.state.user.name"
              class="d-md-block d-none"
              alt="user"
            />
            <div class="user-data d-md-block d-none">
              <p class="user-name">
                <b>{{$store.state.user.name}}</b>
              </p>

              <small class="text-lowercase">{{$store.state.user.user}}</small>
            </div>
            <div class="d-flex justify-content-end d-md-none">
              <span class="material-icons text-dark pr-3 header-icon">person</span>
            </div>
            <div class="dropdown-menu dropdown-menu-right fade-2s" aria-labelledby="user-menu">
              <router-link :to="{path: '/'}" class="dropdown-item" type="button"  v-if="$store.state.user.group == 'gestor'">
                <span class="material-icons">dashboard</span> Dashboard
              </router-link>

              <router-link :to="{name: 'relatorios'}" class="dropdown-item" type="button"  v-if="$store.state.user.group == 'gestor'">
                <span class="material-icons">view_list</span> Relatórios
              </router-link>

              <router-link :to="{name: 'Professores'}" class="dropdown-item" type="button"  v-if="$store.state.user.group == 'gestor'">
                <span class="material-icons">groups_2</span> Professores
              </router-link>

              <router-link :to="{name: 'script'}" class="dropdown-item" type="button"  v-if="$store.state.user.group == 'gestor'">
                <span class="material-icons">code</span> Script
              </router-link>

              <router-link :to="{name: 'slide'}" class="dropdown-item" type="button"  v-if="$store.state.user.group == 'gestor'">
                <span class="material-icons">collections</span> Slide
              </router-link>
               
              <router-link :to="{name: 'editcategoriascursos'}" class="dropdown-item" type="button"  v-if="$store.state.user.group == 'gestor'">
                <span class="material-icons">collections</span> Categorias
              </router-link>

              <router-link :to="{name: 'modelo-certificado'}" class="dropdown-item" type="button"  v-if="$store.state.user.group == 'gestor'">
                <span class="material-icons">school</span> Certificado
              </router-link> 
                
              <router-link :to="{name: 'meuscursos'}" v-if="$store.state.user.group == 'aluno'" class="dropdown-item" type="button">
                <span class="material-icons">work</span> Continue seus cursos
              </router-link>
              <router-link :to="{name: 'certificados'}" v-if="$store.state.user.group == 'aluno'" class="dropdown-item" type="button">
                <span class="material-icons">flag</span> Certificados
              </router-link> 
              <router-link :to="{name: 'chamados'}" class="dropdown-item" type="button">
                <span class="material-icons">flag</span> Meus Chamados
              </router-link>
              <router-link :to="{name: 'Stripe'}" class="dropdown-item" type="button"   v-if="$store.state.user.group == 'gestor'">
                <span class="material-icons">account_balance_wallet</span> Stripe
              </router-link>
              <router-link :to="{name: 'Vimeo'}" class="dropdown-item" type="button"   v-if="$store.state.user.group == 'gestor'">
                <span class="material-icons">token</span> Vimeo
              </router-link>
              <router-link :to="{name: 'myshare'}" class="dropdown-item" type="button"   v-if="$store.state.user.group == 'gestor'">
                <span class="material-icons">share</span> Compartilhar Cursos
              </router-link>
              <router-link :to="{name: 'share'}" class="dropdown-item" type="button"   v-if="$store.state.user.group == 'gestor'">
                <span class="material-icons">share</span> Amplie portfólio
              </router-link>
              <router-link :to="{name: 'adquiridos'}" class="dropdown-item" type="button"   v-if="$store.state.user.group == 'gestor'">
                <span class="material-icons">view_list</span> Cursos Adquiridos
              </router-link>
              <router-link :to="{name: 'vouchers'}" class="dropdown-item" type="button"  v-if="$store.state.user.group == 'gestor'">
                <span class="material-icons">local_activity</span> Vouchers
              </router-link>
              <router-link :to="{name: 'meuperfil'}" class="dropdown-item" type="button">
                <span class="material-icons">person</span> Meu Perfil
              </router-link>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" type="button" @click="logout">
                <span class="material-icons danger">exit_to_app</span> Sair
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!--
    <div class="container  sub-menu">
      <div class="row">
        <div class="col px-5">
          <ul class="navbar-nav flex-row sub-menu-nav">
                        <li class="nav-item" v-if="$store.state.user.group == 'gestor'">
              <router-link :to="{path: '/'}" class="nav-link">Dashboard</router-link>
            </li>
                                    <li class="nav-item" v-if="$store.state.user.group == 'gestor'">
              <router-link :to="{name: 'relatorios'}" class="nav-link">Relatórios</router-link>
            </li>
            <div v-if="$wlType === 'pdv'">
              <li class="nav-item" v-if="$store.state.user.group == 'gestor'">
                <router-link :to="{name: 'cupons'}" class="nav-link">Cupons</router-link>
              </li>
            </div>
            <li class="nav-item" v-if="$store.state.user.group == 'gestor'">
              <router-link :to="{name: 'Professores'}" class="nav-link">Professores</router-link>
            </li>
            <li class="nav-item" v-else>
              <router-link :to="{path: '/'}" class="nav-link">Todos os Cursos</router-link>
            </li>
            <li class="nav-item" v-if="$store.state.user.group == 'aluno'">
              <router-link :to="{name: 'meuscursos'}" class="nav-link">Continue seus cursos</router-link>
            </li>
            <li class="nav-item d-none d-md-block" v-if="$store.state.user.group == 'aluno'">
              <router-link :to="{name: 'certificados'}" class="nav-link">Certificados</router-link>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  -->
  <!--

    CHAT DESATIVADO

  -->
  
    <Chat />
  
<!--
    CHAT DESATIVADO


  -->
    <div class="submenu"></div>
    <Sidebar :visible.sync="visibleLeft" :baseZIndex="1000">
      <ul class="navbar-nav text-dark">
        <li class="nav-item">
          <router-link :to="{path: '/'}" class="nav-link">Todos os Cursos</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'meuscursos'}" class="nav-link">Continue seus cursos</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'certificados'}" class="nav-link">Certificados</router-link>
        </li>
      </ul>
    </Sidebar>
  </section>
</template>

<script>
import ProdutosBuscar from "@/components/ProdutosBuscar.vue";
import Sidebar from "primevue/sidebar";
import Chat from "@/components/Chat/index.vue";
import Dialog from "primevue/dialog";
export default {
  name: "TheHeader",
  components: {
    ProdutosBuscar,
    Sidebar,
    Chat,
    Dialog
    
  },
  data() {
    return {
      Popup:false,
      notifications:[],
      count:0,
      notificacao:{
        title:'',
        text:'',
        _created:''
        
      },
      notlist:false,
      visibleLeft: false,
      items: [
        {
          label: "Categorias",
          icon: "pi pi-fw pi-file",
          items: [
            {
              label: "New",
              icon: "pi pi-fw pi-plus",
              items: [
                {
                  label: "Bookmark",
                  icon: "pi pi-fw pi-bookmark",
                  to: "/meu-perfil",
                },
                {
                  label: "Video",
                  icon: "pi pi-fw pi-video",
                },
              ],
            },
            {
              label: "Delete",
              icon: "pi pi-fw pi-trash",
            },
            {
              separator: true,
            },
            {
              label: "Export",
              icon: "pi pi-fw pi-external-link",
            },
          ],
        },
      ],
      notificacoes: [
        {
          Titulo: "Teste",
          mensagem: "Descrição do teste de notificação",
        },
      ],
    };
  },
  filters: {
    formatDate(value) {
      /*
      var options = { year: "numeric", month: "numeric", day: "numeric" };
      return value.toLocaleString("pt-BR", options);*/
      var date = new Date(value * 1000); // converte para data
      date = date.toLocaleDateString("pt-BR");
      return date;
    },
  },
  methods: {
    Notification(){ 
        this.$http
        .get("/api/aluno/notification")
        .then((response) => {
            this.notifications = response.data['notification']; 
            this.count   = response.data['count']; 
        })
        .catch((error) => {
          console.error(error);
        });
    },
    CheckNotification(){
      this.$http
        .get("/api/aluno/checkNotification")
        .then(() => {
          this.Notification();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    PopupNot(id){
      this.notlist = false;
      this.Popup = true;
      this.notificacao = this.notifications.filter(function(item) {
        return item._id == id
      })[0];
    },
    OpeNot(){
      if(this.notlist){
        this.notlist = false;
      }else{
        this.notlist = true;
        this.CheckNotification();
      }
    },
    openModal() {
      //this.$store.commit("SET_MODAL", true);
    },  
    async logout() {
      await this.$store.dispatch("LOGOUT");
      await this.$router.push({ name: "login" });
    },
    iniciarLoop() {
      this.Notification(); // Execute a função imediatamente.
      setInterval(this.Notification, 300000); // Em seguida, configure o intervalo para chamar a função a cada 30 segundos.
    }
  },
  created() {
    this.iniciarLoop();
  },
  mounted() {
    document.title = this.$titleSite;
    //this.Notification();
  }
};
</script>

<style lang="scss" scoped>
.text-not{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
}
.hs{
  font-size: 10pt;
  color: var(--primary);
}
.li-not:hover{
  background: #f4f9ff;
}
.ul-not{
  position: absolute;
  top: 65px;
  background: white;
  height: 80vh;
  z-index: 100;
  width: 500px;
  max-width: 90vw;
}
.numb{
  position: absolute;
  background: var(--primary);
  filter: drop-shadow(2px 4px 6px black);
  width: 20px;
  height: 20px;
  text-align: -webkit-center;
  border-radius: 50%;
  margin-left: -23px;
  margin-top: 5px;
  padding: 2px;
  font-weight: bold;
  color:#ffffff;
}
.not{
  color: var(--primary);
  cursor: pointer;
  font-size: 45pt;
}
@media screen and (max-width: 900px){
  .header-icon {
    padding: 0 !important;
    margin: 0 !important;
    color: var(--dark-2) !important;
    font-size: 30pt;
  }
  .not{
    font-size: 30pt;
  }
}
.menu-responsive {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  background: #03abeb;
  padding: 0.5rem;
  border-radius: 50%;
  color: white;
}

.dropdown-item.active, .dropdown-item:active{
  background-color: var(--primary-shade) !important;
  color:#ffffff !important;
}
</style>